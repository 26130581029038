import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

export enum TOAST_TYPE {
  ERROR = 'error',
  INFO = 'info',
  SUCCESS = 'success',
  WARNING = 'warning',
  ACKNOWLEDGE = 'acknowledge'
}

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  constructor(private toastrService: ToastrService) { }

  showToast(icon: string, message: string, toastType: TOAST_TYPE) {
    this.toastrService.info('SHOWING TOAST', 'Info', {
      payload: { message: message, icon: icon, toastType: toastType }
    })
  }

}